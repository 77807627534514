<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <Card class="main-content">
        <template #content>
          <div style="text-align: right; margin-bottom: 1rem">
            <Button @click="handleShowExcludeListDialog(null)">
              Add New List
            </Button>
          </div>
          <DataTable :value="dataSource" responsiveLayout="scroll">
            <Column field="name" header="Name">
              <template #body="{ data }">
                {{ data.name }}
              </template>
            </Column>
            <Column field="exclude_words" header="Exclude Words">
              <template #body="{ data }">
                {{ data.exclude_words?.join(", ") }}
              </template>
            </Column>
            <Column
              :exportable="false"
              style="min-width: 8rem"
              columnKey="action"
            >
              <template #header>
                <img src="@/assets/icons/Setting.png" alt="setting" />
              </template>
              <template #body="{ index, data }">
                <div>
                  <Button
                    @click="toggle(index, $event)"
                    class="p-button-text"
                    style="padding: 0"
                  >
                    <img src="@/assets/icons/More.png" alt="more" />
                  </Button>
                  <OverlayPanel
                    :ref="
                      (el) => {
                        ops[index] = el;
                      }
                    "
                  >
                    <a href="#" @click="handleShowExcludeListDialog(index)">
                      <div class="menuItem">Edit</div>
                    </a>
                    <a href="#" @click="deleteExcludeList(data.id)">
                      <div class="menuItem">Delete</div>
                    </a>
                  </OverlayPanel>
                </div>
              </template>
            </Column>
          </DataTable>

          <Dialog header="Exclude List" v-model:visible="showExcludeListDialog">
            <div>
              Name
              <InputText
                type="text"
                v-model="excludeListState.name"
                style="width: 100%"
              />
            </div>

            <div>
              Exclude Words
              <Textarea
                v-model="excludeListState.exclude_words_str"
                style="width: 100%"
              />
            </div>

            <template #footer>
              <Button
                label="Cancel"
                icon="pi pi-times"
                @click="handleHideExcludeListDialog"
                class="p-button-text"
              />
              <Button
                label="Save"
                icon="pi pi-check"
                @click="saveExcludeList"
                autofocus
              />
            </template>
          </Dialog>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import Textarea from "primevue/textarea";

import ProductTrendService from "@/services/product_trend_service";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Card,
    Column,
    DataTable,
    Dialog,
    InputText,
    OverlayPanel,
    Textarea,
  },

  setup() {
    onMounted(() => {
      listExcludeList();
    });

    const confirm = useConfirm();
    const toast = useToast();

    const blocked = ref(true);
    const dataSource = ref([]);

    const showExcludeListDialog = ref(false);
    const excludeListState = reactive({
      id: null,
      name: null,
      exclude_words_str: null,
    });

    const ops = ref([]);

    const toggle = (index, event) => {
      ops.value[index].toggle(event);
    };

    const listExcludeList = () => {
      blocked.value = true;
      ProductTrendService.listExcludeList()
        .then(({ data }) => {
          dataSource.value = data.exclude_lists;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const handleShowExcludeListDialog = (index) => {
      if (index === null) {
        // Create
        excludeListState.id = null;
        excludeListState.name = null;
        excludeListState.exclude_words_str = null;
      } else {
        // Update
        excludeListState.id = dataSource.value[index].id;
        excludeListState.name = dataSource.value[index].name;
        excludeListState.exclude_words_str =
          dataSource.value[index].exclude_words.join(", ");
      }

      showExcludeListDialog.value = true;
    };

    const handleHideExcludeListDialog = () => {
      showExcludeListDialog.value = false;
    };

    const saveExcludeList = () => {
      if (excludeListState.id === null) {
        createExcludeList();
      } else {
        updateExcludeList();
      }
    };

    const createExcludeList = () => {
      let exclude_words = excludeListState.exclude_words_str
        .split(",")
        .map((s) => s.trim())
        .filter((s) => s !== "");

      let payload = {
        name: excludeListState.name,
        exclude_words: [...new Set(exclude_words)], //unique
      };

      blocked.value = true;
      ProductTrendService.createExcludeList(payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Product Trend",
            detail: "Saved Exclude List",
            life: 1000,
          });

          listExcludeList();
        })
        .catch((e) => {
          console.error(e);

          toast.add({
            severity: "error",
            summary: "Product Trend",
            detail: "Save Exclude List fail",
            life: 3000,
          });
        })
        .finally(() => {
          showExcludeListDialog.value = false;
          blocked.value = false;
        });
    };

    const updateExcludeList = () => {
      let exclude_words = excludeListState.exclude_words_str
        .split(",")
        .map((s) => s.trim())
        .filter((s) => s !== "");

      let payload = {
        id: excludeListState.id,
        name: excludeListState.name,
        exclude_words: [...new Set(exclude_words)], //unique
      };

      blocked.value = true;
      ProductTrendService.updateExcludeList(excludeListState.id, payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Product Trend",
            detail: "Saved Exclude List",
            life: 1000,
          });

          listExcludeList();
        })
        .catch((e) => {
          console.error(e);

          toast.add({
            severity: "error",
            summary: "Product Trend",
            detail: "Save Exclude List fail",
            life: 3000,
          });
        })
        .finally(() => {
          showExcludeListDialog.value = false;
          blocked.value = false;
        });
    };

    const deleteExcludeList = (id) => {
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          blocked.value = true;

          ProductTrendService.deleteExcludeList(id)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Product Trend",
                detail: "Deleted Exclude list",
                life: 3000,
              });
            })
            .catch((e) => {
              console.error(e);
              toast.add({
                severity: "error",
                summary: "Product Trend",
                detail: "Delete Exclude list fail",
                life: 3000,
              });
            })
            .finally(() => {
              blocked.value = false;
              listExcludeList();
            });
        },
      });
    };

    return {
      blocked,
      dataSource,
      excludeListState,

      ops,
      toggle,

      showExcludeListDialog,
      handleShowExcludeListDialog,
      handleHideExcludeListDialog,

      saveExcludeList,
      deleteExcludeList,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  margin-top: 1rem;
}

.menuItem {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  margin-left: -1rem;
  margin-right: -1rem;

  &:hover {
    background: #dddddd;
  }
}
</style>
